<template>
    <transition name="fade-up-in" appear>
        <div>
            <div v-if="shown || shown2"
                 :class="{'speech':true, 'down':shown2}">
                <div class="mx-2"><img alt="Logo" src="./assets/android-chrome-192x192.png" style="width: 50px;"></div>
                <div class="mx-2"><b>Install PPL Mobile</b></div>
                <div class="mx-2">Add this shortcut to your home screen for easy access and a better experience.</div>
                <hr />
                <div v-if="shown && !shown2">
                    <button class="install-button" @click="installPWA">
                        Install
                    </button>
                </div>
                <div  v-if="shown2" class="mx-2">Tap <embed src="/assets/ios-share.svg" /> then <span style="font-size: 1rem;font-weight:bold;">"Add to Home Screen"</span></div>
            </div>
        </div>
    </transition>
</template>


<script>
    export default {
        data: () => ({
            installEvent: undefined,
            shown: false,
            shown2: false,
        }),

        beforeMount() {
            window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault()
                this.installEvent = e
                this.shown = true
            })
        },


        mounted() {
            const vm = this;
            if (vm.shown) return;
            vm.shown2 = vm.showIosInstallModal();
        },

        methods: {
            installPWA() {
                this.installEvent.prompt()
                this.installEvent.userChoice.then((choice) => {
                    this.dismissPrompt() // Hide the banner once the user's clicked
                    if (choice.outcome === 'accepted') {
                        // Do something additional if the user chose to install
                    } else {
                        // Do something additional if the user declined
                    }
                })
            },

            dismissPrompt() {
                this.shown = false
            },

            showIosInstallModal() {
                // detect if the device is on iOS
                const isIos = () => {
                    const userAgent = window.navigator.userAgent.toLowerCase();
                    return /iphone|ipad|ipod/.test(userAgent);
                };

                // check if the device is in standalone mode
                const isInStandaloneMode = () => {
                    return (
                        "standalone" in window.navigator &&
                        window.navigator.standalone
                    );
                };

                return isIos() && !isInStandaloneMode();
            }
        }
    }
</script>


<style lang="scss">
    .mx-2 {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    /* (A) SPEECH BOX */
    .speech {
        /* (A1) FONT & COLORS */
        background: white;
        color: black;
        /* (A2) DIMENSIONS */
        border-radius: 10px;
        position: fixed;
        bottom: 1rem;
        width: 90vw;
        left: 50%;
        align-items: center;
        justify-content: center;
        background: white;
        color: black;
        transform: translate(-50%, 0);
        margin: 0 auto;
        padding: 0.5rem 0 0.5rem 0;
    }

    /* (B) USE ::AFTER TO CREATE THE "CALLOUT" */
    .speech::after {
        position: absolute;
        display: block;
        width: 0;
        content: "";
        border: 15px solid transparent;
    }

    /* (C) "CALLOUT" DIRECTIONS */
    .speech.down::after {
        border-top-color: white;
        border-bottom: 0;
        bottom: -15px;
        left: calc(50% - 15px);
    }

    .install-button {
        font-size: inherit;
        margin: 0 0 0 0.5rem;
        padding: 0.25em 0.5em;
        background-color: var(--bright);
        border: 0;
        border-radius: 4px;
        line-height: 1;
        text-transform: uppercase;
        font-weight: 600;
    }

</style>