<template>
    <div class="home mx-2">
        <img alt="Logo" src="./assets/Official_Logo_LIB.png">
        <h1>Welcome to Pasadena Public Library</h1>
        <h2>
            Visit our <a href="https://cityofpasadena.net/library" target="_blank">mobile site</a>
        </h2>
    </div>
</template>

<script>
    export default {
        name: 'HomeView',
    }
</script>
